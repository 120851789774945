/**
 * @typedef {Object} BuildSpecOptions
 * @property {Object} dailyReports - reports to show
 * @property {Number} barWidth - bar width to use for bar graph
 * @property {Boolean} isDark - true if dark mode used
 * @property {Number} secondsOk - title for tooltip
 * @property {Number} secondsWarning - title for tooltip
 */
/**
 *
 * @param {BuildSpecOptions} options
 * @returns
 */
export const buildBuysAvgWaitTimeSpec = (options) => {
  const {
    dailyReports,
    barWidth,
    isDark,
    secondsOk = 1200,
    secondsWarning = 1500,
  } = options;

  if (!dailyReports?.reports || dailyReports.reports.length <= 0) {
    return {};
  }

  let domainValues = [0];
  let genDomainValue = 0;
  if (dailyReports && dailyReports.max && dailyReports.max["buysAvgWaitTime"]) {
    while (genDomainValue < dailyReports.max["buysAvgWaitTime"]) {
      genDomainValue += 30 * 60; // up in 30 minutes intervals
      domainValues.push(genDomainValue);
    }
  }

  if (domainValues.length < 2) {
    // By default show 00:00 to 00:30
    domainValues = [0, 1800];
  }

  const hasBuysAvgWaitTime = dailyReports.reports.some(
    (r) =>
      r.buysAvgWaitTime !== null &&
      r.buysAvgWaitTime !== undefined &&
      r.buysAvgWaitTime !== 0
  );

  const buysAvgWaitTimeLayers = !hasBuysAvgWaitTime
    ? []
    : [
        {
          params: [
            {
              name: "highlight_bar",
              select: {
                type: "point",
                encodings: ["x"],
                on: "mouseover",
                nearest: true,
              },
            },
          ],
          mark: {
            width: barWidth,
            type: "bar",
          },
          transform: [
            {
              calculate: "utcFormat(datum.businessDate,'%b %e, %Y')",
              as: "title",
            },
          ],
          encoding: {
            y: {
              field: "buysAvgWaitTime",
              type: "quantitative",
              scale: {
                domain: [Math.min(...domainValues), Math.max(...domainValues)],
              },
            },
            color: {
              value: "#f05050",
              condition: [
                {
                  param: "highlight_bar",
                  empty: false,
                  value: "#333333",
                },
                {
                  test: `datum.buysAvgWaitTime < ${secondsOk}`,
                  value: "#66bd78",
                },
                {
                  test: `datum.buysAvgWaitTime < ${secondsWarning}`,
                  value: "#FDB45C",
                },
              ],
            },
            tooltip: [
              { field: "title" },
              {
                field: "buysAvgWaitTime",
                type: "quantitative",
                title: "Average Wait Time",
                format: "",
                formatType: "avgWaitTimeStamp",
              },
            ],
          },
        },
      ];

  return {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    data: { values: dailyReports?.reports || [] },
    encoding: {
      x: {
        field: "businessDate",
        type: "temporal",
        axis: {
          labels: false,
          title: null,
          grid: false,
          ticks: false,
          domain: false,
        },
      },
      y: {
        axis: {
          labels: true,
          title: null,
          grid: true,
          ticks: true,
          domain: true,
          values: domainValues,
          format: "",
          formatType: "avgWaitTimeStamp",
        },
      },
    },
    layer: [...buysAvgWaitTimeLayers],
    config: {
      customFormatTypes: true,
      legend: {
        orient: "top",
        layout: { top: { anchor: "middle" } },
        symbolType: "square",
      },
      style: {
        cell: {
          stroke: "transparent",
        },
      },
    },
  };
};
