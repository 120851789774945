/**
 * @typedef {Object} BuildSpecOptions
 * @property {Object} dailyReports - reports to show
 * @property {Number} barWidth - bar width to use for bar graph
 * @property {Boolean} isDark - true if dark mode used
 */
/**
 *
 * @param {BuildSpecOptions} options
 * @returns
 */

const tooltip = [
  { field: "title" },
  {
    field: "customerCount",
    type: "quantitative",
    title: "Count",
  },
  {
    field: "customerCountComp",
    type: "quantitative",
    title: "Comp",
  },
  {
    field: "growth",
    type: "quantitative",
    title: "Growth",
    format: ",.1%",
  },
];

const titleTransform = {
  calculate: "utcFormat(datum.businessDate,'%b %e, %Y')",
  as: "title",
};

export const buildCustomerCountSpec = (options) => {
  const { dailyReports, barWidth, isDark } = options;
  if (!dailyReports?.reports || dailyReports.reports.length <= 0) {
    return {};
  }

  const hasCustomerCount = dailyReports.reports.some(
    (r) => r.customerCount !== null && r.customerCount !== undefined
  );

  const customerCountLayer = !hasCustomerCount
    ? []
    : [
        {
          params: [
            {
              name: "highlight_bar",
              select: { type: "point", on: "mouseover" },
            },
          ],
          mark: {
            type: "bar",
            color: "#66bd78",
            width: barWidth,
          },
          transform: [titleTransform],
          encoding: {
            y: {
              field: "customerCount",
              type: "quantitative",
            },
            color: {
              datum: "Count",
              condition: [
                {
                  param: "highlight_bar",
                  empty: false,
                  value: "#333333",
                },
                {
                  test: "datum.customerCount < datum.customerCountComp",
                  value: "#f05050",
                },
              ],
            },
            tooltip: tooltip,
          },
        },
      ];

  return {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    data: { values: dailyReports?.reports || [] },
    transform: [
      {
        calculate:
          "datum.sales > 0 && datum.customerCountComp > 0 ? datum.customerCountCompPM / datum.customerCountComp : 0",
        as: "growth",
      },
    ],
    encoding: {
      x: {
        field: "businessDate",
        type: "temporal",
        axis: {
          labels: false,
          title: null,
          grid: false,
          ticks: false,
          domain: false,
        },
      },
      y: {
        axis: {
          labels: false,
          title: null,
          grid: false,
          ticks: false,
          domain: false,
        },
      },
      color: {
        scale: {
          domain: ["Count", "Comps"],
          range: ["#66bd78", isDark ? "#7e7e7e" : "#dcdcdc"],
        },
        legend: null,
      },
    },
    layer: [
      {
        params: [
          {
            name: "businessDateDot",
            select: {
              type: "point",
              encodings: ["x"],
              on: "mousemove",
              nearest: true,
            },
          },
        ],
        mark: {
          type: "circle",
        },
        transform: [titleTransform],
        encoding: {
          x: {
            field: "businessDate",
            type: "temporal",
          },
          opacity: {
            value: 0,
          },
          tooltip: tooltip,
        },
      },
      {
        mark: {
          type: "area",
          interpolate: "natural",
          color: isDark ? "#7e7e7e" : "#dcdcdc",
        },
        encoding: {
          y: {
            field: "customerCountComp",
            type: "quantitative",
          },
          color: {
            datum: "Comps",
          },
        },
      },
      ...customerCountLayer,
      {
        params: [
          {
            name: "customerCountDot",
            select: {
              type: "point",
              encodings: ["x"],
              on: "mousemove",
            },
          },
        ],
        mark: {
          type: "circle",
          interpolate: "natural",
          color: isDark ? "#5c5c5c" : "#bababa",
          size: 90,
        },
        transform: [titleTransform],
        encoding: {
          y: {
            field: "customerCountComp",
            type: "quantitative",
          },
          opacity: {
            value: 0,
            condition: [
              {
                param: "customerCountDot",
                empty: false,
                value: 0.7,
              },
            ],
          },
          tooltip: tooltip,
        },
      },
    ],
    config: {
      legend: {
        orient: "top",
        symbolType: "square",
      },
      style: {
        cell: {
          stroke: "transparent",
        },
      },
    },
  };
};
