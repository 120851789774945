/**
 * @typedef {Object} BuildSpecOptions
 * @property {Object} dailyReports - reports to show
 * @property {Number} barWidth - bar width to use for bar graph
 * @property {Boolean} isDark - true if dark mode used
 */
/**
 *
 * @param {BuildSpecOptions} options
 * @returns
 */

const tooltip = [
  { field: "title" },
  {
    field: "avgTransGoal",
    type: "quantitative",
    title: "Goal",
    format: "$,.2f",
  },
  {
    field: "avgTrans",
    type: "quantitative",
    title: "Avg Transaction",
    format: "$,.2f",
  },
  {
    field: "avgTransComp",
    type: "quantitative",
    title: "Comp",
    format: "$,.2f",
  },
  {
    field: "growth",
    type: "quantitative",
    title: "Growth",
    format: ",.1%",
  },
];

const titleTransform = {
  calculate: "utcFormat(datum.businessDate,'%b %e, %Y')",
  as: "title",
};

export const buildAvgTransSpec = (options) => {
  const { dailyReports, barWidth, isDark } = options;
  if (!dailyReports?.reports || dailyReports.reports.length <= 0) {
    return {};
  }

  const hasAvgTrans = dailyReports.reports.some(
    (r) => r.avgTrans !== null && r.avgTrans !== undefined
  );

  const avgTransLayer = !hasAvgTrans
    ? []
    : [
        {
          params: [
            {
              name: "highlight_bar",
              select: { type: "point", on: "mouseover" },
            },
          ],
          mark: {
            type: "bar",
            color: "#66bd78",
            width: barWidth,
          },
          transform: [titleTransform],
          encoding: {
            y: {
              field: "avgTrans",
              type: "quantitative",
            },
            color: {
              datum: "Avg. Trans",
              condition: [
                {
                  param: "highlight_bar",
                  empty: false,
                  value: "#333333",
                },
                {
                  test: "datum.avgTrans < datum.avgTransComp",
                  value: "#f05050",
                },
              ],
            },
            tooltip: tooltip,
          },
        },
      ];

  return {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    data: { values: dailyReports?.reports || [] },
    transform: [
      {
        calculate:
          "datum.sales > 0 && datum.avgTransComp > 0 ? datum.avgTransCompPM / datum.avgTransComp : 0",
        as: "growth",
      },
    ],
    encoding: {
      x: {
        field: "businessDate",
        type: "temporal",
        axis: {
          labels: false,
          title: null,
          grid: false,
          ticks: false,
          domain: false,
        },
      },
      y: {
        axis: {
          labels: false,
          title: null,
          grid: false,
          ticks: false,
          domain: false,
        },
      },
      color: {
        scale: {
          domain: ["Goal", "Avg. Trans", "Comps"],
          range: ["#00b7ff", "#66bd78", isDark ? "#7e7e7e" : "#dcdcdc"],
        },
        legend: null,
      },
    },
    layer: [
      {
        params: [
          {
            name: "businessDateDot",
            select: {
              type: "point",
              encodings: ["x"],
              on: "mousemove",
              nearest: true,
            },
          },
        ],
        mark: {
          type: "circle",
        },
        transform: [titleTransform],
        encoding: {
          x: {
            field: "businessDate",
            type: "temporal",
          },
          opacity: {
            value: 0,
          },
          tooltip: tooltip,
        },
      },
      {
        mark: {
          type: "area",
          interpolate: "natural",
          color: isDark ? "#7e7e7e" : "#dcdcdc",
        },
        encoding: {
          y: {
            field: "avgTransComp",
            type: "quantitative",
          },
          color: {
            datum: "Comps",
          },
        },
      },
      {
        mark: {
          type: "line",
          interpolate: "natural",
          color: "#00b7ff",
        },
        encoding: {
          y: {
            field: "avgTransGoal",
            type: "quantitative",
          },
          color: {
            datum: "Goal",
          },
        },
      },
      ...avgTransLayer,
      {
        params: [
          {
            name: "avgTransDot",
            select: {
              type: "point",
              encodings: ["x"],
              on: "mousemove",
            },
          },
        ],
        mark: {
          type: "circle",
          interpolate: "natural",
          color: isDark ? "#5c5c5c" : "#bababa",
          size: 90,
        },
        transform: [titleTransform],
        encoding: {
          y: {
            field: "avgTransComp",
            type: "quantitative",
          },
          opacity: {
            value: 0,
            condition: [
              {
                param: "avgTransDot",
                empty: false,
                value: 0.7,
              },
            ],
          },
          tooltip: tooltip,
        },
      },
      {
        mark: {
          type: "circle",
          interpolate: "natural",
          color: "#00b7ff",
          size: 90,
        },
        encoding: {
          y: {
            field: "avgTransGoal",
            type: "quantitative",
          },
          opacity: {
            value: 0,
            condition: [
              {
                param: "avgTransDot",
                empty: false,
                value: 1,
              },
            ],
          },
        },
      },
    ],
    config: {
      legend: {
        orient: "top",
        symbolType: "square",
      },
      style: {
        cell: {
          stroke: "transparent",
        },
      },
    },
  };
};
