/**
 * @typedef {Object} BuildSpecOptions
 * @property {Object} dailyReports - reports to show
 * @property {Number} barWidth - bar width to use for bar graph
 * @property {Boolean} isDark - true if dark mode used
 */
/**
 *
 * @param {BuildSpecOptions} options
 * @returns
 */
export const buildSalesSpec = (options) => {
  const { dailyReports, barWidth, isDark } = options;
  if (!dailyReports?.reports || dailyReports.reports.length <= 0) {
    return {};
  }

  const tooltip = [
    { field: "title" },
    {
      field: "salesGoal",
      type: "quantitative",
      title: "Goal",
      format: "$,.2f",
    },
    {
      field: "sales",
      type: "quantitative",
      title: "Sales",
      format: "$,.2f",
    },
    {
      field: "salesComp",
      type: "quantitative",
      title: "Comp",
      format: "$,.2f",
    },
    {
      field: "growth",
      type: "quantitative",
      title: "Growth",
      format: ",.1%",
    },
  ];

  const titleTransform = {
    calculate: "utcFormat(datum.businessDate,'%b %e, %Y')",
    as: "title",
  };

  const hasSales = dailyReports.reports.some(
    (r) => r.sales !== null && r.sales !== undefined
  );

  const salesLayer = !hasSales
    ? []
    : [
        {
          params: [
            {
              name: "highlight_bar",
              select: { type: "point", on: "mouseover" },
            },
          ],
          mark: {
            type: "bar",
            color: "#66bd78",
            width: barWidth,
          },
          transform: [titleTransform],
          encoding: {
            y: {
              field: "sales",
              type: "quantitative",
            },
            color: {
              datum: "Sales",
              condition: [
                {
                  param: "highlight_bar",
                  empty: false,
                  value: "#333333",
                },
                {
                  test: "datum.sales < datum.salesComp",
                  value: "#f05050",
                },
              ],
            },
            tooltip: tooltip,
          },
        },
      ];

  return {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    data: { values: dailyReports.reports },
    transform: [
      {
        calculate:
          "(datum.sales > 0 && datum.salesComp > 0) ? datum.salesCompPM / datum.salesComp : 0",
        as: "growth",
      },
    ],
    encoding: {
      x: {
        field: "businessDate",
        type: "temporal",
        axis: {
          labels: false,
          title: null,
          grid: false,
          ticks: false,
          domain: false,
        },
      },
      y: {
        axis: {
          labels: false,
          title: null,
          grid: false,
          ticks: false,
          domain: false,
        },
      },
      color: {
        scale: {
          domain: ["Goal", "Sales", "Comps"],
          range: ["#00b7ff", "#66bd78", isDark ? "#7e7e7e" : "#dcdcdc"],
        },
      },
    },
    layer: [
      {
        params: [
          {
            name: "businessDateDot",
            select: {
              type: "point",
              encodings: ["x"],
              on: "mousemove",
              nearest: true,
            },
          },
        ],
        mark: {
          type: "circle",
        },
        transform: [titleTransform],
        encoding: {
          x: {
            field: "businessDate",
            type: "temporal",
          },
          opacity: {
            value: 0,
          },
          tooltip: tooltip,
        },
      },
      {
        mark: {
          type: "area",
          interpolate: "natural",
          color: isDark ? "#7e7e7e" : "#dcdcdc",
        },
        encoding: {
          y: {
            field: "salesComp",
            type: "quantitative",
          },
          color: {
            datum: "Comps",
          },
        },
      },
      {
        mark: {
          type: "line",
          interpolate: "natural",
          color: "#00b7ff",
        },
        encoding: {
          y: {
            field: "salesGoal",
            type: "quantitative",
          },
          color: {
            datum: "Goal",
          },
        },
      },
      ...salesLayer,
      {
        params: [
          {
            name: "salesCompDot",
            select: {
              type: "point",
              encodings: ["x"],
              on: "mousemove",
            },
          },
        ],
        mark: {
          type: "circle",
          interpolate: "natural",
          color: isDark ? "#5c5c5c" : "#bababa",
          size: 90,
        },
        transform: [titleTransform],
        encoding: {
          y: {
            field: "salesComp",
            type: "quantitative",
          },
          opacity: {
            value: 0,
            condition: [
              {
                param: "salesCompDot",
                empty: false,
                value: 0.7,
              },
            ],
          },
          tooltip: tooltip,
        },
      },
      {
        mark: {
          type: "circle",
          interpolate: "natural",
          color: "#00b7ff",
          size: 90,
        },
        encoding: {
          y: {
            field: "salesGoal",
            type: "quantitative",
          },
          opacity: {
            value: 0,
            condition: [
              {
                param: "salesCompDot",
                empty: false,
                value: 1,
              },
            ],
          },
        },
      },
    ],
    config: {
      legend: {
        orient: "top",
        layout: { top: { anchor: "middle" } },
        symbolType: "square",
      },
      style: {
        cell: {
          stroke: "transparent",
        },
      },
    },
  };
};
