import { defineSpec } from "@/utils/vega";

/**
 * Build bounceback campaign spec
 * @param {import("@/utils/vega").VegaSpecOptions} options - options to resolve graph specification
 */
export function buildBouncebackSpec(options) {
  return defineSpec(options, () => ({
    encoding: {
      x: {
        field: "date",
        type: "temporal",
        axis: {
          format: "%b %e",
          labelAngle: -50,
          title: null,
          grid: false,
        },
      },
      y: {
        axis: {
          title: null,
        },
      },
    },
    layer: [
      {
        mark: {
          type: "line",
          color: "#00b7ff",
          clip: true,
        },
        encoding: {
          y: {
            field: "count",
            type: "quantitative",
          },
        },
      },
      {
        transform: [
          {
            calculate: "utcFormat(datum.date,'%b %e, %Y')",
            as: "title",
          },
        ],
        mark: {
          type: "circle",
          interpolate: "natural",
          color: "#00b7ff",
          size: 90,
        },
        encoding: {
          y: {
            field: "count",
            type: "quantitative",
          },
          tooltip: [
            { field: "title" },
            { title: "Bouncebacks", field: "count" },
          ],
        },
      },
    ],
  }));
}
