/**
 * @typedef {Object} BuildSpecOptions
 * @property {Object} dailyReports - reports to show
 * @property {Number} barWidth - bar width to use for bar graph
 * @property {Boolean} isDark - true if dark mode used
 * @property {String} countTitle - title for tooltip
 */
/**
 *
 * @param {BuildSpecOptions} options
 * @returns
 */
export const buildBinsSpec = (options) => {
  const { dailyReports, barWidth, isDark, countTitle = "" } = options;
  if (!dailyReports?.reports || dailyReports.reports.length <= 0) {
    return {};
  }

  return {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    data: { values: dailyReports?.reports || [] },
    encoding: {
      x: {
        axis: {
          labels: true,
          title: null,
          grid: false,
          ticks: true,
          domain: true,
        },
      },
      y: {
        axis: {
          labels: true,
          title: null,
          grid: true,
          ticks: true,
          domain: true,
        },
      },
    },
    layer: [
      {
        params: [
          {
            name: "highlight_bar",
            select: { type: "point", on: "mouseover" },
          },
        ],
        mark: {
          width: barWidth,
          type: "bar",
        },
        transform: [
          {
            calculate: "datum.categoryName",
            as: "title",
          },
        ],
        encoding: {
          x: {
            field: "count",
            type: "quantitative",
          },
          y: {
            field: "categoryName",
          },
          color: {
            value: "#00b7ff",
            condition: [
              {
                param: "highlight_bar",
                empty: false,
                value: "#333333",
              },
            ],
          },
          tooltip: [
            { field: "title" },
            {
              field: "count",
              type: "quantitative",
              title: countTitle,
            },
          ],
        },
      },
    ],
    config: {
      customFormatTypes: true,
      legend: {
        orient: "top",
        layout: { top: { anchor: "middle" } },
        symbolType: "square",
      },
      style: {
        cell: {
          stroke: "transparent",
        },
      },
    },
  };
};
