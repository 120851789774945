import vuetify from "@/plugins/vuetify";

/**
 * @typedef {import("vega-embed").VisualizationSpec & { dark?: Boolean }} VegaSpec
 */

/**
 * @typedef {Object} VegaSpecOptions
 * @property {Array} values - values to be shown in graph
 * @property {String} [title] - title to be shown on graph view
 * @property {Boolean} [dark] - dark flag to render graph optimized for dark mode
 * @property {String} [datasetName] - name of dataset refer if additional manual actions are needed
 *
 * @typedef {VegaSpecOptions & { barWidth: ResponsiveWidth }} VegaBarSpecOptions
 */

/**
 * @callback DefineSpecCallback
 * @param {VegaSpec} options - default options and some additional
 * @returns {VegaSpec}
 */

/**
 * Create Vega specification
 * @param {VegaSpecOptions} options - options to create Vega graph specification
 * @param {DefineSpecCallback} callback - callback to be resolved with access to default options and some extended options
 * @returns {VegaSpec}
 */
export function defineSpec(options, callback) {
  if (options.values.length === 0) {
    return {};
  }

  const specOptions = {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    dark: options.dark ?? vuetify.framework.theme.dark,
    title: options.title,
    data: {
      name: options.datasetName,
      values: options.values,
    },
  };

  return { ...specOptions, ...(callback(specOptions) ?? {}) };
}

/**
 * @typedef {Object} ResponsiveWidth
 * @property {Number} default - the default width to apply if no width is specified for breakpoint
 * @property {Number} [xs] - the width for xs breakpoint
 * @property {Number} [sm] - the width for sm breakpoint
 * @property {Number} [md] - the width for md breakpoint
 * @property {Number} [lg] - the width for lg breakpoint
 * @property {Number} [xl] - the width for xl breakpoint
 */

/**
 * Resolve width based on screen breakpoint
 * @param {ResponsiveWidth} width - the width to resolve at specific breakpoint
 * @returns {Number}
 */
export function responsiveWidth(width) {
  const { breakpoint } = vuetify.framework;

  if (breakpoint.xl) {
    return width.xl ?? width.md ?? width.sm ?? width.xs ?? width.default;
  } else if (breakpoint.lg) {
    return width.lg ?? width.md ?? width.sm ?? width.default;
  } else if (breakpoint.md) {
    return width.md ?? width.sm ?? width.xs ?? width.default;
  } else if (breakpoint.sm) {
    return width.sm ?? width.xs ?? width.default;
  } else if (breakpoint.xs) {
    return width.xs ?? width.default;
  }

  return width.default;
}
