import { defineSpec, responsiveWidth } from "@/utils/vega";

/**
 * Build customer count spec
 * @param {import("@/utils/vega").VegaBarSpecOptions} options - options to resolve graph specification
 */
export function buildActualCustomerCountSpec(options) {
  return defineSpec(options, () => ({
    encoding: {
      x: {
        field: "businessDate",
        type: "temporal",
        axis: {
          labels: false,
          title: null,
          grid: false,
        },
      },
      y: {
        axis: {
          title: null,
          grid: false,
        },
      },
    },
    layer: [
      {
        mark: {
          type: "bar",
          color: "#00b7ff",
          width: responsiveWidth(options.barWidth),
        },
        transform: [
          {
            calculate: "utcFormat(datum.businessDate,'%b %e, %Y')",
            as: "title",
          },
        ],
        encoding: {
          y: {
            field: "customerCount",
            type: "quantitative",
          },
          tooltip: [
            { field: "title" },
            {
              field: "customerCount",
              type: "quantitative",
              title: "Count",
            },
          ],
        },
      },
    ],
  }));
}
