/**
 * @typedef {Object} BuildSpecOptions
 * @property {Object} dailyReports - reports to show
 * @property {Number} barWidth - bar width to use for bar graph
 * @property {Boolean} isDark - true if dark mode used
 */
/**
 *
 * @param {BuildSpecOptions} options
 * @returns
 */
export const buildBackstockAgeSpec = (options) => {
  const {
    dailyReports,
    barWidth,
    isDark = "",
    legendColumns = 0,
    allCategories = [],
    age0_90Label = "Aged 0-90",
    age91_180Label = "Aged 91-180",
    age181_365Label = "Aged 181-365",
    age366PlusLabel = "Aged 366+",
  } = options;
  if (!dailyReports?.reports || dailyReports.reports.length <= 0) {
    return {};
  }

  let tickValues = {};
  dailyReports.reports.forEach((r) => {
    if (!tickValues[r.categoryName]) {
      tickValues[r.categoryName] = 0;
    }
    tickValues[r.categoryName] += r.count;
  });
  const maxCount = Math.max(
    ...Object.entries(tickValues).map(([_, value]) => value)
  );

  let ticksLabels = [];
  const maxTick = (Math.floor(maxCount / 25) + 1) * 25;
  for (let i = 0; i <= maxTick; i += 25) {
    ticksLabels.push(i);
  }

  return {
    $schema: "https://vega.github.io/schema/vega-lite/v5.json",
    data: { values: dailyReports?.reports || [] },
    params: [
      {
        name: "highlight_bar",
        select: { type: "point", on: "mouseover", fields: ["categoryName"] },
      },
      {
        name: "selected_category",
        select: { type: "point", fields: ["age"], toggle: "true" },
        bind: { legend: "click" },
        value: [
          { age: age0_90Label },
          { age: age91_180Label },
          { age: age181_365Label },
          { age: age366PlusLabel },
        ],
      },
    ],
    transform: [
      {
        calculate: "datum.categoryName",
        as: "title",
      },
      {
        calculate: `datum.age == '${age0_90Label}' ? datum.count : 0`,
        as: "age_0_90",
      },
      {
        calculate: `datum.age == '${age91_180Label}' ? datum.count : 0`,
        as: "age_91_180",
      },
      {
        calculate: `datum.age == '${age181_365Label}' ? datum.count : 0`,
        as: "age_181_365",
      },
      {
        calculate: `datum.age == '${age366PlusLabel}' ? datum.count : 0`,
        as: "age_366_plus",
      },
      {
        calculate: "datum.count",
        as: "total_count",
      },
      {
        calculate:
          `datum.age == '${age0_90Label}' ? 0 : ` +
          `datum.age == '${age91_180Label}' ? 5 : ` +
          `datum.age == '${age181_365Label}' ? 10 : ` +
          `datum.age == '${age366PlusLabel}' ? 15 : 100`,
        as: "age_sort",
      },
      {
        joinaggregate: [
          {
            field: "age_0_90",
            op: "sum",
            as: "age_0_90_sum",
          },
          {
            field: "age_91_180",
            op: "sum",
            as: "age_91_180_sum",
          },
          {
            field: "age_181_365",
            op: "sum",
            as: "age_181_365_sum",
          },
          {
            field: "age_366_plus",
            op: "sum",
            as: "age_366_plus_sum",
          },
          {
            field: "total_count",
            op: "sum",
            as: "total_sum",
          },
        ],
        groupby: ["categoryName"],
      },
      {
        filter: {
          or: [{ selection: "selected_category" }],
        },
      },
    ],
    mark: {
      height: barWidth,
      type: "bar",
      strokeWidth: 2,
    },
    encoding: {
      x: {
        axis: {
          labels: true,
          title: null,
          domain: true,
          grid: true,
          gridOpacity: 0.5,
          ticks: true,
          values: ticksLabels,
        },
        field: "count",
        type: "quantitative",
      },
      y: {
        axis: {
          title: null,
          grid: true,
          ticks: true,
          type: "ordinal",
          tickBand: "extent",
        },
        scale: { domain: allCategories },
        field: "categoryName",
      },
      order: { field: "age_sort", type: "ordinal" },
      stroke: {
        field: "age",
        scale: {
          domain: [
            age0_90Label,
            age91_180Label,
            age181_365Label,
            age366PlusLabel,
          ],
          range: ["#37CD55", "#FFA538", "#FF673D", "#FF2424"],
        },
      },
      color: {
        field: "age",
        scale: {
          domain: [
            age0_90Label,
            age91_180Label,
            age181_365Label,
            age366PlusLabel,
          ],
          range: ["#9BE6AA", "#FFD29B", "#FFB39E", "#FF9191"],
        },
      },
      opacity: {
        value: 0.8,
        condition: [
          {
            param: "highlight_bar",
            empty: false,
            value: 1,
          },
        ],
      },
      tooltip: [
        { field: "title" },
        {
          field: "age_0_90_sum",
          type: "quantitative",
          title: age0_90Label,
        },
        {
          field: "age_91_180_sum",
          type: "quantitative",
          title: age91_180Label,
        },
        {
          field: "age_181_365_sum",
          type: "quantitative",
          title: age181_365Label,
        },
        {
          field: "age_366_plus_sum",
          type: "quantitative",
          title: age366PlusLabel,
        },
        {
          field: "total_sum",
          type: "ordinal",
          title: "Total",
        },
      ],
    },
    config: {
      customFormatTypes: true,
      legend: {
        orient: "top",
        layout: { top: { anchor: legendColumns > 0 ? "end" : "middle" } },
        columns: legendColumns,
        symbolType: "M0,-1H5V1H0Z",
        labelOffset: 30,
        type: "symbol",
        title: null,
        symbolSize: 150,
        titlePadding: 0,
      },
      style: {
        cell: {
          stroke: "transparent",
        },
      },
    },
  };
};
